<template>
	<!-- 实名认证列表 -->
	<div class="bullMarket-real-auth">
		<div class="el-content">
			<div class="kd-pacel">检索参数</div>
			<div>
				<span class="f13">审核状态：</span>
				<a-radio-group v-model:value="status" button-style="solid" @change="getBullRealAuth(1,info.limit)">
					<a-radio-button :value="1">审核中</a-radio-button>
					<a-radio-button :value="2">审核通过</a-radio-button>
					<a-radio-button :value="3">审核未通过</a-radio-button>
				</a-radio-group>
			</div>
		</div>
		
		<div class="el-content">
			<a-table :pagination="false" row-key="user_id" :data-source="info.list" :columns="[
				{title:'用户id',dataIndex:'user_id'},
				{title:'真实姓名',dataIndex:'truename'},
				{title:'证件信息',dataIndex:'card',slots:{customRender:'card'}},
				{title:'地址',dataIndex:'address',slots:{customRender:'address'}},
				{title:'状态',dataIndex:'status',slots:{customRender:'status'}},
				{title:'认证时间',dataIndex:'create_time'},
				{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
			]">
				<template #card="{record}">
					<div><span class="f12">身份证件号：</span>{{record.idcard}}</div>
					<div><span class="f12">证件有效期：</span>{{record.export_time}}</div>
					<div style="display: flex;align-items: flex-start;"><span class="f12">证 件 信 息：</span>
						<a-image class="bmra-img" :src="record.p_card_img"></a-image>
						<a-image class="bmra-img" :src="record.n_card_img"></a-image>
					</div>
				</template>
				<template #address="{record}">
					<div><span class="f12">省/市/区：</span>{{record.province}}{{record.city}}{{record.area}}</div>
					<div><span class="f12">详细地址：</span>{{record.address}}</div>
				</template>
				<template #status="{record}">
					<a-tag color="#999" v-if="record.status == 1">待审核</a-tag>
					<a-tag color="#00CC66" v-if="record.status == 2">审核通过</a-tag>
					<a-tag color="#FF3366" v-if="record.status == 3">审核失败</a-tag>
					<div v-if="record.status == 3"><span class="f12">驳回理由：</span>{{record.msg}}</div>
				</template>
				<template #action="{record}">
					<div class="kd-opera-btn" v-if="status == 1">
						<a-tooltip title="通过审核">
							<div class="kd-btn kd-btn-primary" @click="passCheck(record.user_id)"><i class="ri-check-line ri-lg"></i></div>
						</a-tooltip>
						<a-tooltip title="驳回审核">
							<div class="kd-btn kd-btn-danger" @click="showRefuse(record)"><i class="ri-close-line ri-lg"></i></div>
						</a-tooltip>
					</div>
				</template>
			</a-table>
			<div class="pager">
				<a-pagination show-size-changer
					:default-current="info.page"
					:total="info.count"
					@showSizeChange="(p,e)=>{getBullRealAuth(info.page,e)}"
					@change="(e)=>{getBullRealAuth(e,info.limit)}"
				/>
			</div>
		</div> 
		<a-modal title="驳回审核" :visible="show.refuse"  @ok="submitRefuse" @cancel="show.refuse=false" width="500px">
			<a-textarea placeholder="驳回理由" v-model="form.msg" allow-clear :autosize="{ minRows: 4, maxRows: 6 }"/>
		</a-modal>
	</div>
</template>

<script>
import userModel from '@/api/user.js'
import { reactive, toRefs } from 'vue'
export default{
	setup(){
		const _d = reactive({
			status:1,
			info:{
				list:[],
				page:1,
				limit:10,
				count:0
			},
			form:{
				id:'',
				msg:'',
				status:3
			},
			show:{
				refuse:false
			}
		})
		getBullRealAuth(1,_d.info.limit)
		function getBullRealAuth(page,limit){
			userModel.getAccountAuth(page,limit,{status:_d.status},res=>_d.info = {limit,...res})
		}
		function showRefuse(e){
			_d.form.id = e.user_id
			_d.show.refuse = true
		}
		const submitRefuse = () =>userModel.refuseOrPassAccountAuth(_d.form,()=>getBullRealAuth(_d.info.page,_d.info.limit))
		const passCheck = (id) =>userModel.refuseOrPassAccountAuth({id,status:2},()=>getBullRealAuth(_d.info.page,_d.info.limit))
		return{
			...toRefs(_d),
			getBullRealAuth,
			submitRefuse,
			passCheck
		}
	}
}
</script>

<style lang="scss">
	.bullMarket-real-auth{
		.bmra-img{
			width: 60px;
			height: 40px;
			margin-right: 8px;
			cursor: pointer;
		}
	}
</style>
